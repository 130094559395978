let routes = [{
		path: '/login',
		component: 'layouts/login',
		children: [{
			component: 'login/index'
		}]
	},{
	path: '/',
	name: 'admin',
	component: 'layouts/main',
	redirect: {
		name: 'index'
	},
	children: [
		{
			meta: {
				title: '首页',
			},
			component: 'index/index',
			path: '/',
		},
		{
			meta: {
				title: 'FB 广告发布',
				permission: 'fb-ads',
				redirect: 'carousel,single,video'
			},
			component: 'fb-ads/index',
			children: [{
				meta: {
					title: '轮播广告',
					permission: 'fb-ads-carousel'
				},
				name: 'fb-ads_carousel',
				path: 'carousel',
				component: 'fb-ads/carousel/index',
				redirect: {
					name: 'fb-ads_carousel_list'
				},
				children: [{
					name: 'fb-ads_carousel_list',
					path: 'list',
					component: 'fb-ads/carousel/list'
				}, {
					path: 'distincted-list',
					component: 'fb-ads/carousel/distincted-list',
				}]
			}, {
				meta: {
					title: '单图广告',
					permission: 'fb-ads-single'
				},
				path: 'single',
				component: 'fb-ads/single/index'
			}, {
				meta: {
					title: '视频广告',
					permission: 'fb-ads-video'
				},
				path: 'video',
				component: 'fb-ads/video/index'
			}]
		},
		{
			meta: {
				title: 'FB 广告账户管理',
				permission: 'accounts'
			},
			component: 'fb-accounts/index',
			redirect: {
				name: 'fb-accounts_info'
			},
			children: [{
				name: 'fb-accounts_info',
				path: 'info',
				component: 'fb-accounts/info'
			}]
		},
		{
			meta: {
				title: 'FB 广告内容配置',
				permission: 'contents',
				redirect: 'context,pixel,category-url,country-language,audience'
			},
			component: 'fb-contents/index',
			children: [{
				meta: {
					title: '文案',
					permission: 'contents-context'
				},
				name: 'fb-contents_context',
				path: 'context',
				component: 'fb-contents/context'
			}, {
				meta: {
					title: '品类&URL',
					permission: 'contents-category-url'
				},
				path: 'category-url',
				component: 'fb-contents/category-url'
			}, {
				meta: {
					title: 'Page&Pixel',
					permission: 'contents-pixel'
				},
				path: 'pixel',
				component: 'fb-contents/page-pixel'
			}, {
				meta: {
					title: '国家&语言',
					permission: 'contents-country-language'
				},
				path: 'country-language',
				component: 'fb-contents/country-language'
			}, {
				meta: {
					title: 'Audience',
					permission: 'contents-audience'
				},
				path: 'audience',
				component: 'fb-contents/audience'
			}, {
				meta: {
					title: '站点&国家',
					permission: 'website-country'
				},
				path: 'website-country',
				component: 'fb-contents/website-country'
			}]
		},
		{
			meta: {
				title: 'Budget Plan',
				permission: 'budget-plan',
				redirect: 'plan,config'
			},
			component: 'budget-plan/index',
			children: [{
				meta: {
					title: '调整计划',
					permission: 'budget-plan-plan'
				},
				path: 'plan',
				component: 'budget-plan/plan/index'
			}, {
				meta: {
					title: '规则配置',
					permission: 'budget-plan-config'
				},
				path: 'config',
				component: 'budget-plan/config/index'
			}]
		},
		{
			meta: {
				title: 'Ad Switch',
				permission: 'ad-switch',
				redirect: 'history,config'
			},
			component: 'ad-switch/index',
			children: [{
				meta: {
					title: '开关记录',
					permission: 'ad-switch-history'
				},
				path: 'history',
				component: 'ad-switch/history/index'
			}, {
				meta: {
					title: '规则配置',
					permission: 'ad-switch-config'
				},
				path: 'config',
				component: 'ad-switch/config/index'
			}]
		},
		{
			meta: {
				title: 'Ad Strategy',
				permission: 'ad-strategy',
				redirect: 'config,history'
			},
			component: 'ad-strategy/index',
			children: [{
				meta: {
					title: '策略配置',
					permission: 'ad-strategy-config'
				},
				path: 'config',
				component: 'ad-strategy/config/index'
			}, {
				meta: {
					title: '策略生效记录',
					permission: 'ad-strategy-history'
				},
				path: 'history',
				component: 'ad-strategy/history/index'
			}]
		},
		{
			meta: {
				title: '日线图',
				permission: 'dataset'
			},
			component: 'dataset/index'
		},
		{
			meta: {
				title: '数据看板',
				permission: 'summary'
			},
			component: 'summary/index'
		},
		{
			meta: {
				title: '脚本列表'
			},
			component: 'scripts/index'
		},
		{
			meta: {
				title: 'FB广告发布记录',
				permission: 'records'
			},
			component: 'records/index'
		},
		{
			meta: {
				title: 'Campaign/Adset 报表',
				permission: 'daliyform'
			},
			component: 'daliyform/index'
		},
		{
			meta: {
				title: '站点花费&余额报表',
				permission: 'cost-balance'
			},
			component: 'cost-balance/index'
		},
		{
			meta: {
				title: '测款广告',
				permission: 'newtest',
				redirect: 'basic,advance'
			},
			component: 'newtest/index',
			children: [{
				meta: {
					title: '测款广告数据',
					permission: 'newtest-basic'
				},
				path: 'basic',
				component: 'newtest/basic'
			}, {
				meta: {
					title: '进阶测款',
					permission: 'newtest-advance'
				},
				path: 'advance',
				component: 'newtest/advance'
			}]
		},
		{
			meta: {
				title: '商品销量报表',
				permission: 'sales'
			},
			component: 'sales/index'
		},
		{
			meta: {
				title: '选款报表',
				permission: 'selection'
			},
			component: 'selection/index'
		},
		{
			meta: {
				title: 'Campaign & LTV报表',
				permission: 'campaign-ltv',
				redirect: 'campaign,ltv'
			},
			component: 'campaign-ltv/index',
			children: [{
				meta: {
					title: 'Campaign',
					permission: 'campaign-ltv-campaign',
				},
				name: 'campaign-ltv_campaign',
				path: 'campaign',
				component: 'campaign-ltv/campaign'
			}, {
				meta: {
					title: 'LTV',
					permission: 'campaign-ltv-ltv',
				},
				path: 'ltv',
				component: 'campaign-ltv/ltv'
			}]
		},
		{
			meta: {
				title: '复购率',
				permission: 'repurchase'
			},
			component: 'repurchase/index'
		},
		{
			meta: {
				title: '账号管理',
				permission: 'settings_account'
			},
			component: 'settings/account/index',
			redirect: {
				name: 'settings_account_list'
			},
			children: [{
				name: 'settings_account_list',
				component: 'settings/account/list',
				path: 'list'
			}, {
				name: 'settings_account_id',
				props: true,
				component: 'settings/account/_id',
				path: ':id'
			}]
		},
		{
			meta: {
				title: '账户权限管理',
				permission: 'settings_account-permissions',
				redirect: 'facebook'
			},
			component: 'settings/token/index',
			children: [
				{
					name: 'settings_token_facebook',
					path: 'facebook',
					component: 'settings/token/facebook',
					meta: {
						title: 'Facebook',
						permission: 'settings_account-permissions-fb'
					}
				}, 
				// {
				// 	path: 'google',
				// 	component: 'settings/token/google'
				// }, {
				// 	path: 'tiktok',
				// 	component: 'settings/token/tiktok'
				// },
			]
		},
		{
			meta: {
				title: '站点管理',
			},
			component: 'settings/sites/index'
		},
		{
			meta: {
				title: '日志'
			},
			component: 'changelog/index'
		},
		{
			meta: {
				title: 'Feed 任务队列',
				permission: 'feeds_queue'
			},
			component: 'feeds/queue/index'
		},
		{
			meta: {
				title: 'Feed 内容配置',
				permission: 'feeds_content',
				redirect: 'product,tag'
			},
			component: 'feeds/content/index',
			children: [{
				meta: {
					title: '商品库',
					permission: 'feeds_content-product',
				},
				component: 'feeds/content/product',
				path: 'product'
			}, {
				meta: {
					title: 'TAG',
					permission: 'feeds_content-tag',
				},
				component: 'feeds/content/tag',
				path: 'tag'
			}]
		},
		{
			meta: {
				title: 'Shopify商品同步',
				permission: 'tools_shopify'
			},
			component: 'tools/shopify'
		},
		{
			meta: {
				title: 'SPU查询',
				permission: 'tools_lookup-spu'
			},
			component: 'tools/lookup-spu'
		},
		{
			component: 'tools/image-editor'
		},
		{
			meta: {
				title: 'JSCX报表',
				permission: 'jscx',
				redirect: 'data,terms,plan,term-adjust'
			},
			component: 'jscx/index',
			children: [{
				meta: {
					title: 'Data',
					permission: 'jscx-data',
				},
				name: 'jscx_data',
				path: 'data',
				component: 'jscx/data'
			}, {
				meta: {
					title: 'Terms',
					permission: 'jscx-terms',
				},
				name: 'jscx_terms',
				path: 'terms',
				component: 'jscx/terms'
			}, {
				meta: {
					title: 'Plan',
					permission: 'jscx-plan',
				},
				name: 'jscx_plan',
				path: 'plan',
				component: 'jscx/plan'
			}, {
				meta: {
					title: 'Offers',
					permission: 'jscx-offers'
				},
				name: 'jscx_offers',
				path: 'offers',
				component: 'jscx/offers'
			}, {
				meta: {
					title: 'Term Adjust',
					permission: 'jscx-term-adjust'
				},
				name: 'jscx_term_adjust',
				path: 'term-adjust',
				component: 'jscx/term-adjust'
			}]
		},
		{
			meta: {
				title: 'JSCX关系维护',
				permission: 'jscx-relation',
				redirect: 'domain-direction,term-direction'
			},
			component: 'jscx-relation/index',
			children: [{
				meta: {
					title: '域名投放方向维护表',
					permission: 'jscx-relation-domain-direction',
				},
				name: 'jscx_relation_domain_direction',
				path: 'domain-direction',
				component: 'jscx-relation/domain-direction'
			},{
				meta: {
					title: '关键词方向维护表',
					permission: 'jscx-relation-term-direction',
				},
				name: 'jscx_relation_term_direction',
				path: 'term-direction',
				component: 'jscx-relation/term-direction'
			}]
		},
		{
			component: 'error/404',
			name: 'notfound',
		},{
			name: 'forbid',
			component: 'error/403'
		},
		{
			redirect: {
				name: 'notfound'
			},
			path: '/*'
		}
	]
}]


// 获取路由信息方法
let getRoutes = function() {
	createRoute(routes)
	return routes
}
// 自动生成路由
function createRoute(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (!arr[i].component) return
		let val = removeTrailingIndex(arr[i].component)
		arr[i].name = arr[i].name || getRouteName(val)
		arr[i].path = arr[i].path || getRoutePath(val)
		let componentFun = import(`../../views/${arr[i].component}.vue`)
		arr[i].component = () => componentFun
		if (arr[i].children && arr[i].children.length > 0) {
			createRoute(arr[i].children)
		}
	}
}
function removeTrailingIndex(str) {
	// 获取最后一个/的索引
	let index = str.lastIndexOf('/')
	let val = str.substring(index + 1, str.length)
	if (val === 'index') {
		return str.substring(index, -1)
	}
	return str
}

function getRouteName(val) {
	return val.replace(/\/_[a-z]+/g, '').replace(/\//g, '_')
}

function getRoutePath(val) {
	return `/${val.replace(/_/g, ':')}`
}

export default getRoutes()
